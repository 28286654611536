import { StatusCodes } from 'http-status-codes'

import { ToastMethods } from '@/util/toast'
import router from '@/router'
import { HttpError } from './http'

export interface ErrorHandler {
  handleError: (err: unknown) => void
}

export const useErrorHandler = (toast: ToastMethods): ErrorHandler => {
  const handleError = (err: unknown) => {
    console.log(err)

    if (err instanceof HttpError) {
      const { data } = err
      toast.showError(data)

      if (data.status === StatusCodes.UNAUTHORIZED) {
        router.push('/signin')
      }
    } else {
      toast.showError({ title: 'Ocorreu um erro desconhecido.' })
    }
  }

  return { handleError }
}
