
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
      default: 'Erro ao carregar dados.',
    },
    retryCb: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
  },
})
