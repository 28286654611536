
import { NavigationOpts } from '@/data/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {},
  props: {
    navigation: {
      type: String as PropType<NavigationOpts>,
      default: 'none',
      validator: (value: NavigationOpts) =>
        ['menu', 'back', 'none'].includes(value),
    },
  },
  methods: {
    handleBack() {
      this.$router.back()
    },
    handleMenu() {
      this.$store.commit('expandSideMenu')
    },
  },
})
