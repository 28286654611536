
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String as PropType<string>,
      default: 'Sem dados para exibir.',
    },
    reloadCb: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
  },
})
